$navbar-height: 60px;
$round-corners: 0.25rem;

@mixin clickable {
  cursor: pointer;
}

@mixin shadowed($blur: 1rem) {
  box-shadow: 0 0 $blur rgb(0, 0, 0, 0.2);
}

@mixin rounded {
  border-radius: $round-corners;
}

@mixin center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin center-x {
  display: flex;
  justify-content: center;
}

@mixin center-y {
  display: flex;
  align-items: center;
}

@mixin spread {
  display: flex;
  justify-content: space-between;
}

@mixin w-100 {
  width: 100%;
}

@mixin darken {
  filter: brightness(0.95);
}

@mixin brighten {
  filter: brightness(1.05);
}

.flex-row {
  display: flex;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.center {
  @include center();
}

.center-x {
  @include center-x();
}

.center-y {
  @include center-y();
}

.spread {
  @include spread();
}

.wh-100 {
  width: 100%;
  height: 100%;
}

.darken {
  @include darken();
}

.brighten {
  @include brighten();
}

.clickable {
  @include clickable();
}

.shadowed {
  @include shadowed();
}

.shadowed-sm {
  @include shadowed(0.25rem);
}

.fs-xl {
  font-size: 3rem !important;
}

.pre {
  white-space: pre-wrap;
}
