$primary: #803480;
$secondary: #878787;

$font-size-base: 0.8rem;

$navbar-light-color: rgba($primary, 0.55);
$navbar-light-hover-color: rgba($primary, 0.7);
$navbar-light-active-color: rgba($primary, 1);
$navbar-light-disabled-color: rgba($secondary, 0.3);

$custom-font-sizes: (
  7: $font-size-base * 0.9,
  8: $font-size-base * 0.8
);

@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";

$font-sizes: map-merge($font-sizes, $custom-font-sizes);

// Position element top right for close buttons etc.
@each $index, $space in $spacers {
  .pa-tr-#{$index} {
    position: absolute !important;
    top: $space / 2 !important;
    right: $space / 2 !important;
    width: fit-content !important;
  }
}
