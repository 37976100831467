@use "styles/utils.scss" as *;
@use "styles/colors.scss" as *;

@import "styles/custom.scss";
@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "bootstrap-icons/font/bootstrap-icons.css";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.app {
  width: 100vw;
  height: 100vh;
}

.content {
  min-width: 100vw;
  min-height: 100vh;
  padding-top: $navbar-height + 20px;
  padding-bottom: 20px;
  background-color: $seashell;
}

.navbar {
  min-height: $navbar-height;
}

.navbar-collapse {
  justify-content: flex-end;
}

input {
  padding: 5px;
}

.paginator {
  .btn {
    margin-right: 5px;
  }
}

tbody {
  tr {
    background-color: $seashell;
    &.clickable {
      &:hover {
        filter: brightness(1.05);
      }
    }
  }
}

.info-card {
  @include shadowed;
  @include rounded;
  @include w-100();
  background-color: white;
  .info-card-header {
    @include w-100();
    @include center-y();
    border-radius: $round-corners $round-corners 0 0;
    padding: 0.5rem 0.75rem;
    color: $light;
    background-color: $primary;
    .title {
      margin: 0 0.75rem 0 0;
    }
  }
  .info-card-body {
    padding: 0.75rem;
    .table-wrap {
      border-radius: $round-corners;
      border: 1px solid $secondary;
      thead {
        border-bottom: 1px solid $secondary;
      }
    }
  }
  .info-card-footer {
    border-radius: 0 0 $round-corners $round-corners;
    padding: 0.5rem 0.75rem;
  }
}

.order-information {
  label {
    white-space: nowrap;
  }
}

.info-tag {
  display: inline-flex;
  min-width: 35px;
  margin: 0.125rem 0;
  &:not(:last-child) {
    margin-right: 0.25rem;
  }
}

.react-calendar__tile--now {
  background-color: $secondary !important;
  color: $light;
  opacity: 0.5;
}

.react-calendar__tile--active {
  background-color: $primary !important;
  opacity: 1;
}

// Fullsize tables
.table-wrap {
  display: block;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  table {
    width: 100%;
    border-spacing: 0;
    th,
    td {
      width: 1%;
      &.collapse {
        width: 0.0000000001%;
      }
    }
  }
}

.table-checkbox {
  width: 16px;
  height: 16px;
}

input.invoice-basis-row {
  max-width: 160px;
}

.options-row {
  div:not(:last-child) {
    margin-bottom: 0.5rem;
  }
}

tr:nth-child(even) {
  @include darken();
}
